<template>
  <b-modal
    ref="my-modal"
    :ok-variant="variant"
    :modal-class="`modal-${variant}`"
    :ok-title="$t('Confirm')"
    :cancel-title="$t('Cancel')"
    centered
    :visible="visible"
    :title="`${deleteParams.title}`"
    @hidden="$emit('close')"
  >
    <b-card-text>{{ deleteParams.body || deleteParams.title }}</b-card-text>
    <slot />
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button @click="closeModal">
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            :variant="variant"
            class="ml-2"
            :disabled="processing"
            @click="handleConfirm"
          >
            <b-spinner
              v-show="processing"
              small
            />
            {{ $t('Confirm') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    deleteParams: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: 'danger',
    },
  },
  methods: {
    handleConfirm() {
      this.$emit('onConfirm')
    },
    closeModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>
